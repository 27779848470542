import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModificationCategorieComponent } from './modification-categorie/modification-categorie.component';
import { Categorie } from '../z-model/categorie';
import { MatTableDataSource } from '@angular/material/table';
import { CategorieService } from '../_services/categorie.service';
import { TypeDepenseService } from '../_services/type-depense.service';
import { TypeDepense } from '../z-model/type-depense';

@Component({
  selector: 'app-parametrages',
  templateUrl: './parametrages.component.html',
  styleUrl: './parametrages.component.css'
})
export class ParametragesComponent implements OnInit {
  listCategories!: Categorie[];
  listTypesDepenses!: TypeDepense[];
  dataSourceCategories = new MatTableDataSource<Categorie>(this.listCategories);
  dataSourceTypesDepenses = new MatTableDataSource<TypeDepense>(this.listTypesDepenses);

  displayedColumnsCategories: string[] = ['libelle', 'prixParGramme', 'actions'];
  displayedColumnsTypesDepenses: string[] = ['libelle', 'actions'];

  constructor(
    private _categorieService: CategorieService,
    private _typesDepenseService : TypeDepenseService,
    public dialog: MatDialog,
  ){}

  ngOnInit(): void {
    this.loadCategories();
    this.loadTypesDepenses();
  }

  loadCategories() {
    this._categorieService.getCategories().subscribe(categories => {
      this.listCategories = categories.filter(category => category.libelle !== 'Diamant');
      this.dataSourceCategories.data = categories.filter(category => category.libelle !== 'Diamant');
    });
  }

  loadTypesDepenses() {
    this._typesDepenseService.getTypeDepense().subscribe(typesDepenses => {
      this.listTypesDepenses = typesDepenses;
      this.dataSourceTypesDepenses.data = typesDepenses;
    });
  }

  editPrixOr(categorie: Categorie) {
    this.dialog.open(ModificationCategorieComponent, {
      width: '600px',
      data: categorie
    });
  }

  editTypeDepense(typeDepense: TypeDepense) {
    this.dialog.open(ModificationCategorieComponent, {
      width: '600px',
      data: typeDepense
    });
  }

  deleteTypeDepense(id: string) {
    if (confirm("Voulez-vous vraiment supprimer ce type de dépense ?")) {
      this._typesDepenseService.deleteTypeDepense(id).subscribe(() => {
        this.loadTypesDepenses();
      });
    }
  }

  addTypeDepense() {
    const dialogRef = this.dialog.open(ModificationCategorieComponent, {
      width: '600px',
      data: { libelle: '' } as TypeDepense
    });
    dialogRef.afterClosed().subscribe(result => {
      this.loadTypesDepenses();
    });
  }
}
