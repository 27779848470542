<div>
    <div *ngIf="utilisateur" class="avatar-container">
        <ng-container *ngIf="utilisateur.photo; else noPhoto">
            <img [src]="getImageUrl(utilisateur.photo)" alt="{{ utilisateur.nom }}" class="avatar mat-elevation-z8"
                style="width: 100px; height: 100px;">
        </ng-container>
        <ng-template #noPhoto>
            <mat-icon class="avatar mat-elevation-z8" style="font-size: 100px;">account_circle</mat-icon>
        </ng-template>
    </div>
    <h4 class="name">Bonjour {{user.Utilisateur?.nom}} {{user.Utilisateur?.prenom}}</h4>
</div>

<mat-divider></mat-divider>

<button mat-button class="menu-button" routerLink="commandes">
    <mat-icon>reorder</mat-icon>
    <span class="mdc-button__label">Ventes</span>
</button>

<button mat-button class="menu-button" routerLink="clients">
    <mat-icon>people</mat-icon>
    <span class="mdc-button__label">Clients</span>
</button>

<button mat-button class="menu-button" routerLink="articles">
    <mat-icon> list_alt</mat-icon>
    <span class="mdc-button__label">Articles</span>
</button>

<button mat-button class="menu-button" routerLink="modeles">
    <mat-icon>list_alt</mat-icon>
    <span class="mdc-button__label">Modèles</span>
</button>

<button *ngIf="user.Utilisateur?.role.name === Role.User || user.Utilisateur?.role.name === Role.Admin" mat-button class="menu-button" routerLink="depenses">
    <mat-icon>list_alt</mat-icon>
    <span class="mdc-button__label">Dépenses</span>
</button>

<button *ngIf="user.Utilisateur?.role.name === Role.Admin" mat-button class="menu-button" routerLink="utilisateurs">
    <mat-icon>people</mat-icon>
    <span class="mdc-button__label">Utilisateurs</span>
</button>

<button *ngIf="user.Utilisateur?.role.name === Role.Gestionnaire || user.Utilisateur?.role.name === Role.Admin" mat-button class="menu-button" routerLink="parametrages">
    <mat-icon>settings</mat-icon>
    <span class="mdc-button__label">Paramétrages</span>
</button>

<button *ngIf="user.Utilisateur?.role.name === Role.Gestionnaire || user.Utilisateur?.role.name === Role.Admin" mat-button class="menu-button" routerLink="statistiques">
    <mat-icon>history</mat-icon>
    <span class="mdc-button__label">Recherche avancée</span>
</button>

<button *ngIf="user.Utilisateur?.role.name === Role.Admin" mat-button class="menu-button" routerLink="statistiquesPayements">
    <mat-icon>dashboard</mat-icon>
    <span class="mdc-button__label">Caisses</span>
</button>

<button mat-button class="menu-button" routerLink="profile">
    <mat-icon>person</mat-icon>
    <span class="mdc-button__label">Mon profil</span>
</button>