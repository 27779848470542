import { ModePayementService } from '../../_services/mode-payement.service';
import { TypeDepenseService } from '../../_services/type-depense.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DepenseService } from '../../_services/depense.service';
import { ModePayement } from '../../z-model/mode-payement';
import { TypeDepense } from '../../z-model/type-depense';
import { Depense } from '../../z-model/depense';
import { Component } from '@angular/core';
import { Utilisateur } from '../../z-model/utilisateur';
import { TokenStorageService } from '../../_services/token-storage.service';
import { UtilisateurService } from '../../_services/utilisateur.service';
import { MatDialog } from '@angular/material/dialog';
import { SuccessDialogComponent } from '../../success-dialog/success-dialog.component';

@Component({
  selector: 'app-depense-from',
  templateUrl: './depense-from.component.html',
  styleUrl: './depense-from.component.css'
})
export class DepenseFromComponent {
  modesPaiement: ModePayement[] = [];
  TypesDepenses: TypeDepense[] = [];
  utilisateur!: Utilisateur;
  depenseForm!: FormGroup;
  depense!: Depense;

  constructor(
    private _modePayementService: ModePayementService,
    private tokenStorageService: TokenStorageService,
    private _utilisateurService: UtilisateurService,
    private _typeDepenseService: TypeDepenseService,
    private _depenseService: DepenseService,
    private dialog: MatDialog,
    private fb: FormBuilder,
  ) {
    this.getDonneesUtilisateur();
    this.loadListeModePaiement();
    this.loadTypesDepenses();
    this.createForm();
  }

  getDonneesUtilisateur() {
    this._utilisateurService.getUtilisateur(this.tokenStorageService.getUser().Utilisateur?.id).subscribe(data => {
      this.utilisateur = data;
    });
  }

  loadTypesDepenses() {
    this._typeDepenseService.getTypeDepense().subscribe(typesDepenses => {
      this.TypesDepenses = typesDepenses;
    });
  }

  loadListeModePaiement() {
    this._modePayementService.getModePayement().subscribe(modes => {
      this.modesPaiement = modes;
    });
  }

  createForm() {
    this.depenseForm = this.fb.group({
      id: '',
      typeDepence: this.fb.group({
        id: ['', Validators.required],
      }),
      dateDepence: [new Date(), Validators.required],
      montant: ['', Validators.required],
      utilisateurCreateur: this.fb.group({
        id: '',
      }),
      modePayement: this.fb.group({
        id: ['', Validators.required],
      })
    });
  }

  onSubmit(): void {
    if (this.depenseForm.valid) {
      this.depenseForm.get('utilisateurCreateur.id')?.patchValue(this.utilisateur.id);
      this._depenseService.addDepense(this.depenseForm.value).subscribe({
        next: () => {
          this.dialog.open(SuccessDialogComponent, {
            data: {
              message: 'Dépense ajoutée avec succès.',
              onCancel: this.onCancel.bind(this)
            }
          });
        },
        error: (err) => {
          this.dialog.open(SuccessDialogComponent, {
            data: {
              message: 'Une erreur s\'est produite lors de l\'ajout de la dépense. La dépense n\'a pas été ajoutée.'
            }
          });
        }
      });
    }
  }

  onCancel(): void {
    this.depenseForm.reset({
      typeDepence: { id: '' },
      dateDepence: new Date(),
      montant: '',
      utilisateurCreateur: '',
      modePayement: { id: '' }
    });
    this.depenseForm.markAsPristine();
    this.depenseForm.markAsUntouched();
    this.depenseForm.updateValueAndValidity();
  }
}
