<div class="modal-content">
    <h2 mat-dialog-title class="modal-header">
        {{ isCategorie ? 'Catégorie' : 'Type de Dépense' }} : {{ data.libelle }}
    </h2>
    <mat-dialog-content>
        <div class="row">
            <!-- Si c'est une catégorie -->
            <div *ngIf="isCategorie" class="col-md-12 mb-3">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Nouveau prix par gramme</mat-label>
                    <input matInput [(ngModel)]="nouveauPrix" type="number">
                </mat-form-field>
            </div>

            <!-- Si c'est un type de dépense -->
            <div *ngIf="isTypeDepense" class="col-md-12 mb-3">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Nouveau libellé</mat-label>
                    <input matInput [(ngModel)]="nouveauLibelle" type="text">
                </mat-form-field>
            </div>
        </div>

        <div class="custom-actions">
            <button mat-button (click)="onCancel()">Annuler</button>
            <button mat-button (click)="updateData()">Enregistrer</button>
        </div>
    </mat-dialog-content>
</div>