<div class="list-container" *ngIf="utilisateur">
    <p class="app-titre-component">Ajout d'une dépense</p>
    <div *ngIf="utilisateur" style="padding: 20px;" class="mat-elevation-z8 appCommandeForm">
        <form [formGroup]="depenseForm" (ngSubmit)="onSubmit()">
            <div class="row">
                <div class="col-md-6 mb-6">
                    <mat-form-field formGroupName=typeDepence class="w-100" appearance="outline">
                        <mat-label>Type de dépense</mat-label>
                        <mat-select formControlName="id">
                            <mat-option *ngFor="let type of TypesDepenses" [value]="type.id">
                                {{ type.libelle }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-6 mb-6">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Date de dépense</mat-label>
                        <input matInput formControlName="dateDepence" required [matDatepicker]="picker">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 mb-6">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Montant</mat-label>
                        <input matInput formControlName="montant">
                    </mat-form-field>
                </div>
                <div class="col-md-6 mb-6">
                    <mat-form-field formGroupName=modePayement class="w-100" appearance="outline">
                        <mat-label>Mode de Paiement</mat-label>
                        <mat-select formControlName="id">
                            <mat-option *ngFor="let mode of modesPaiement" [value]="mode.id">
                                {{ mode.libelle }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div mat-dialog-actions>
                <button mat-button type="button" (click)="onCancel()">Annuler</button>
                <button mat-button [disabled]="!depenseForm.valid" type="submit">Enregistrer</button>
            </div>
        </form>
    </div>
</div>