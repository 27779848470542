import { Component, OnInit } from '@angular/core';
import { ArticleSearchDialogComponent } from '../article-search-dialog/article-search-dialog.component';
import { PdfViewerDialogComponent } from '../../pdf-viewer-dialog/pdf-viewer-dialog.component';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { SuccessDialogComponent } from '../../success-dialog/success-dialog.component';
import { ClientFormComponent } from '../../client/client-form/client-form.component';
import { StatutCommandeService } from '../../_services/statut-commande.service';
import { CommandeLineService } from '../../_services/commande-line.service';
import { MagasinStockService } from '../../_services/magasin-stock.service';
import { ModePayementService } from '../../_services/mode-payement.service';
import { CommandeService } from '../../_services/commande.service';
import { PayementService } from '../../_services/payement.service';
import { MagasinService } from '../../_services/magasin.service';
import { StatutCommande } from '../../z-model/statut-commande';
import { ClientService } from '../../_services/client.service';
import { LigneCommande } from '../../z-model/ligne-commande';
import { MagasinStock } from '../../z-model/magasin-stock';
import { ModePayement } from '../../z-model/mode-payement';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { Payement } from '../../z-model/payement';
import { Commande } from '../../z-model/commande';
import { Article } from '../../z-model/article';
import { Magasin } from '../../z-model/magasin';
import { Client } from '../../z-model/client';
import { Observable } from 'rxjs';
import { ModeleService } from '../../_services/modele.service';

const STATUT_PAYEE = 'Payée';
const STATUT_VALIDEE = 'Validée';

@Component({
  selector: 'app-commande-form',
  templateUrl: './commande-form.component.html',
  styleUrl: './commande-form.component.css'
})
export class CommandeFormComponent implements OnInit {
  filtredArticlesList!: Observable<Article[]>;
  statutsCommande: StatutCommande[] = [];
  lignesCommande: LigneCommande[] = [];
  searchReference = new FormControl();
  payementsCommande: Payement[] = [];
  magasinsStock: MagasinStock[] = [];
  modesPaiement: ModePayement[] = [];
  showPaiements: boolean = false;
  listeMagasins: Magasin[] = [];
  articlesList: Article[] = [];
  availableArticles: Article[] = [];
  listeClients: Client[] = [];
  isMagasinChoosen: boolean = false;
  commandeForm!: FormGroup;
  totalGlobal: number = 0;
  resteAPayer: number = 0;
  totalPaye: number = 0;
  commande!: Commande;
  idCommande!: string;
  isEditing!: boolean;
  statutId!: string;
  isDiamant: boolean = false;

  constructor(
    private _statutCommandeService: StatutCommandeService,
    private _commandeLingneService: CommandeLineService,
    private _modePayementService: ModePayementService,
    private _magasinStockService: MagasinStockService,
    private _commandeService: CommandeService,
    private _payementService: PayementService,
    private _magasinService: MagasinService,
    private _modelesService: ModeleService,
    private _clientService: ClientService,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private router: Router,
  ) {
    this.createForm();
  }

  createForm(data?: Commande) {
    this.commandeForm = this.fb.group({
      commande: this.fb.group({
        magasinId: [data?.magasin.id, Validators.required],
        clientId: [data?.client.id],
        dateCommande: [data?.dateCommande || new Date(), Validators.required],
        statutId: data?.statut.id,
        cadeau: [data?.cadeau],
        atelier: [data?.atelier],
        description: [data?.description, Validators.maxLength(255)],
      }),
      paiements: this.fb.array([
      ]),
      commandeLines: this.fb.array([], [this.minimumCommandeLinesValidator(1)]),
    });
  }

  minimumCommandeLinesValidator(min: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const array = control as FormArray;
      return array.length >= min ? null : { minimumCommandeLines: true };
    };
  }

  ngOnInit(): void {
    this.idCommande = this.route.snapshot.paramMap.get('id')!;
    this.loadListeStatutsCommande();
    this.loadListeModePaiement();
    this.loadListeMagasins();
    this.loadListeClients();
    this.loadMagasinStock();
    this.commandeLines.valueChanges.subscribe(() => {
      this.calculateTotalGlobal();
    });
    this.paiements.valueChanges.subscribe(() => {
      this.calculateTotalPaye();
    });
    this.isEditing = !(this.idCommande === null || this.idCommande === undefined);
    if (this.isEditing) {
      this.getDonneesCommande();
      this.getDonneesLignesCommande();
      this.getDonneesPayement();
    }
  }

  getDonneesCommande() {
    this._commandeService.getCommande(this.idCommande).subscribe(data => {
      this.commande = data;
      this.patchForm(this.commande);
    });
  }

  getDonneesLignesCommande() {
    this._commandeLingneService.getLignesCommande(this.idCommande).subscribe(data => {
      this.lignesCommande = data;
      this.patchLignesCommandeForm(this.lignesCommande);
    });
  }

  getDonneesPayement() {
    this._payementService.getPayement(this.idCommande).subscribe(data => {
      this.payementsCommande = data;
      this.patchPayementsForm(data);
    });
  }

  patchForm(commande: Commande) {
    this.commandeForm.patchValue({
      commande: {
        id: commande.id,
        magasinId: commande.magasin.nom,
        clientId: commande.client?.id,
        dateCommande: commande.dateCommande,
        statutId: commande.statut.id,
        cadeau: commande.cadeau,
        atelier: commande.atelier,
        description: commande.description,
      }
    });
    this.commandeForm.get('commande')?.disable();
  }

  patchPayementsForm(payements: Payement[]) {
    this.paiements.clear();
    payements.forEach((payement: Payement) => {
      this.paiements.push(this.fb.group({
        id: payement.id,
        modePayement: this.fb.group({
          id: [payement.modePayement.id, Validators.required]
        }),
        montantRegle: [payement.montantRegle, [Validators.required, Validators.min(0)]],
        isNew: [false],
        commande: {
          id: this.idCommande,
        },
        datePayement: payement.datePayement
      }));
    });
    this.calculateTotalPaye();
    this.commandeForm.get('paiements')?.disable();
  }

  patchLignesCommandeForm(lignesCommande: LigneCommande[]) {
    this.commandeLines.clear();
    lignesCommande.forEach((lgCommande: LigneCommande) => {
      this.commandeLines.push(this.fb.group({
        photoArticle: lgCommande.article.modele.photo,
        articleId: lgCommande.article.reference,
        prixVente: lgCommande.prixVente,
        poids: lgCommande.article.poids,
        remiseEnPourcentage: lgCommande.remiseEnPourcentage
      }));
    });
    this.handleCalculations();
    this.commandeForm.get('commandeLines')?.disable();
  }

  loadListeStatutsCommande() {
    this._statutCommandeService.getStatutCommande().subscribe(data => {
      this.statutsCommande = data;
    });
  }

  loadListeModePaiement() {
    this._modePayementService.getModePayement().subscribe(modes => {
      this.modesPaiement = modes;
    });
  }

  loadListeMagasins() {
    this._magasinService.getMagasins().subscribe(magasins => {
      this.listeMagasins = magasins;
    });
  }

  loadListeClients() {
    this._clientService.getClients().subscribe(clients => {
      this.listeClients = clients;
    });
  }

  loadMagasinStock(): void {
    this._magasinStockService.getMagasinStock().subscribe(magasinStock => {
      this.magasinsStock = magasinStock;
    });
  }

  get paiements() {
    return this.commandeForm.get('paiements') as FormArray;
  }

  get commandeLines() {
    return this.commandeForm.get('commandeLines') as FormArray;
  }

  addPaiement() {
    this.paiements.push(this.fb.group({
      modePayement: this.fb.group({
        id: ['', Validators.required],
      }),
      montantRegle: [0, [Validators.required, Validators.min(0)]],
      isNew: [true],
      commande: this.fb.group({
        id: '',
      }),
      datePayement: new Date()
    }));
  }

  removePaiement(index: number) {
    this.paiements.removeAt(index);
    this.calculateTotalPaye();
  }

  removeArticle(index: number) {
    this.commandeLines.removeAt(index);
  }

  calculateTotalGlobal() {
    this.totalGlobal = this.commandeLines.controls.reduce((total, article) => {
      // Recalculer la somme avec la réduction
      const prixVente = article.get('prixVente')!.value;
      return total + prixVente;
    }, 0);
    this.calculateResteAPayer();
  }

  calculateTotalPaye() {
    this.totalPaye = this.paiements.controls.reduce((total, paiement) => {
      const montant = paiement.get('montantRegle')!.value;
      return total + montant;
    }, 0);
    this.calculateResteAPayer();
  }

  calculateResteAPayer() {
    this.resteAPayer = this.totalGlobal - this.totalPaye;
  }

  addClient(): void {
    const dialogRef = this.dialog.open(ClientFormComponent, {
      width: '400px',
      data: new Client()
    });

    dialogRef.afterClosed().subscribe(result => {
      this.commandeForm.patchValue({
        commande: {
          clientId: result?.id,
        }
      });
      if (result) {
        this.loadListeClients();
      } else {
        this.loadListeClients();
      }
    });
  }

  onMagasinChange(magasinNom: string) {
    this.filterArtcilesMagasinsStock(magasinNom);
    this.commandeLines.controls.forEach((group) => {
      this.commandeLines.clear();
    });
  }

  filterArtcilesMagasinsStock(magasinNom: string): void {
    const magasinStock = this.magasinsStock.find(stock => stock.statutArticle.libelle === magasinNom);
    if (magasinStock) {
      this.articlesList = magasinStock.articles;
      this.isMagasinChoosen = true;
    } else {
      console.warn("Aucun stock de magasin trouvé pour ce nom.");
      this.isMagasinChoosen = false;
      this.articlesList = [];
    }
  }

  onSubmit(): void {
    if (this.commandeForm.valid) {
      this.handleCalculations();
      this.updateStatut();
      this.updateFormStatut();

      if (!this.isEditing) {
        this.addNewCommande();
      } else {
        this.addNewPaiements();
        this._commandeService.updateCommande(this.commande).subscribe(commande => {
          this.dialog.open(SuccessDialogComponent, {
            data: {
              message: 'La commande est mis à jour avec succès',
              onCancel: this.onCancel.bind(this)
            }
          });
        });
      }
    }
  }

  addNewPaiements(): void {
    const newPaiements = this.commandeForm.get('paiements')?.value.filter((paiement: any) => paiement.isNew);
    newPaiements.forEach((paiement: Payement) => {
      paiement.commande.id = this.idCommande;
    });
    this._payementService.addPayement(newPaiements).subscribe((lignesPayement) => {
      console.log('New payments added successfully:', lignesPayement);
    });
  }

  addNewCommande(): void {
    this.commandeForm.get('commande.magasinId')?.setValue(this.listeMagasins.filter(magasin => magasin.nom === this.commandeForm.get('commande.magasinId')?.value)[0].id);
    this.commandeForm.enable();
    this._commandeService.addCommande(this.commandeForm.value).subscribe(commande => {
      this.dialog.open(SuccessDialogComponent, {
        data: {
          message: 'La nouvelle commande, numéro ' + commande.numeroCommande + ' est ajouté avec succès',
          onCancel: this.onCancel.bind(this)
        }
      });
    });
  }

  updateFormStatut(): void {
    if (this.isEditing) {
      this.commande.statut.id = this.statutsCommande.filter(statut => statut.libelle === this.statutId)[0].id;
    }
    this.commandeForm.patchValue({
      commande: {
        statutId: this.statutsCommande.filter(statut => statut.libelle === this.statutId)[0].id,
      }
    });
  }

  updateStatut(): void {
    if (this.resteAPayer === 0) {
      this.statutId = STATUT_PAYEE;
    } else {
      this.statutId = STATUT_VALIDEE;
    }
  }

  handleCalculations(): void {
    this.calculateTotalGlobal();
    this.calculateTotalPaye();
    this.calculateResteAPayer();
  }

  onCancel() {
    this.commandeForm.reset();
    this.paiements.clear();
    this.commandeLines.clear();
    this.createForm();
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['commandes']);
    });
  }

  isDeletePaymentAllowed(index: number): boolean {
    const paiement = this.paiements.at(index).value;
    return paiement.isNew !== false;
  }

  openArticleSearch(): void {
    const existingArticleIds = this.commandeLines.controls
      .map(group => group.get('articleId')?.value)
      .filter(id => id != null);

    this.availableArticles = this.articlesList.filter(article =>
      !existingArticleIds.includes(article.id)
    );

    const dialogRef = this.dialog.open(ArticleSearchDialogComponent, {
      width: '400px',
      data: { articles: this.availableArticles }
    });

    dialogRef.afterClosed().subscribe(selectedArticle => {
      if (selectedArticle) {
        this.isDiamant = selectedArticle?.modele.categorie.libelle === 'Diamant';
        this.commandeLines.push(this.fb.group({
          photoArticle: { value: selectedArticle.modele.photo, disabled: true },
          articleId: { value: selectedArticle.id, disabled: true },
          prixVente: { value: this.calculatePrixVente(selectedArticle.prix, 0), disabled: this.isDiamant },
          poids: { value: selectedArticle.poids, disabled: true },
          remiseEnPourcentage: { value: 0, disabled: !this.isDiamant },
          prixBase: { value: selectedArticle.prix, disabled: true }
        }));
      }
    });
  }

  downloadRecu(index: number): void {
    const paiement = this.paiements.at(index).value;
    this._payementService.getRecuPayement(paiement.id).subscribe((response: Blob) => {
      const blobUrl = window.URL.createObjectURL(response);
      const safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);
      const fileName = `reçu_paiement_${paiement.datePayement}.pdf`;

      this.dialog.open(PdfViewerDialogComponent, {
        width: '80%',
        height: '80%',
        data: { pdfUrl: safeUrl, fileName }
      });
    });
  }

  calculatePrixVente(prixBase: number, remise: number): number {
    const remiseEnPourcentage = remise / 100;
    return prixBase - (prixBase * remiseEnPourcentage);
  }

  onRemiseChange(remise: number, index: number): void {
    const ligneCommande = this.commandeLines.at(index);
    const prixBase = ligneCommande.get('prixBase')?.value;
    if (prixBase != null) {
      const prixVente = this.calculatePrixVente(prixBase, remise);
      ligneCommande.get('prixVente')?.setValue(prixVente);
    }
  }

  getArticleById(articleId: string): Article | undefined {
    return this.articlesList.find(article => article.id === articleId);
  }

  getImageUrl(photo: string | undefined): string {
    return photo ? this._modelesService.getImageUrl(photo) : 'assets/orynoJewelleryLogo.png';
  }
}
