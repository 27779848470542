import { Component, Inject } from '@angular/core';
import { Categorie } from '../../z-model/categorie';
import { CategorieService } from '../../_services/categorie.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SuccessDialogComponent } from '../../success-dialog/success-dialog.component';
import { TypeDepenseService } from '../../_services/type-depense.service';
import { TypeDepense } from '../../z-model/type-depense';

@Component({
  selector: 'app-modification-categorie',
  templateUrl: './modification-categorie.component.html',
  styleUrl: './modification-categorie.component.css'
})
export class ModificationCategorieComponent {
  isTypeDepense: boolean = false;
  isCategorie: boolean = false;
  nouveauLibelle!: string;
  nouveauPrix!: number;

  constructor(
    public dialogRef: MatDialogRef<ModificationCategorieComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Categorie | TypeDepense,
    private _categorieService: CategorieService,
    private _typeDepenseService: TypeDepenseService,
    public dialog: MatDialog,
  ) {
    if ((data as Categorie).prixParGramme !== undefined) {
      this.isCategorie = true;
      this.nouveauPrix = (data as Categorie).prixParGramme;
    } else if ((data as TypeDepense).libelle !== undefined) {
      this.isTypeDepense = true;
      this.nouveauLibelle = (data as TypeDepense).libelle;
    }
  }

  updateData() {
    if (this.isCategorie) {
      let categorie = this.data as Categorie;
      categorie.prixParGramme = this.nouveauPrix;

      this._categorieService.updateCategorie(categorie).subscribe({
        next: () => {
          this.showSuccessMessage('Catégorie modifiée avec succès.');
        },
        error: () => {
          this.showErrorMessage('Une erreur s\'est produite lors de la modification de la catégorie.');
        }
      });
    } else if (this.isTypeDepense) {
      let typeDepense = this.data as TypeDepense;
      typeDepense.libelle = this.nouveauLibelle;

      if (!typeDepense.id) {
        // Si l'objet ne contient pas d'ID, c'est un nouvel élément
        this._typeDepenseService.updateTypeDepense(typeDepense).subscribe({
          next: () => this.showSuccessMessage('Type de dépense ajouté avec succès.'),
          error: () => this.showErrorMessage('Une erreur s\'est produite lors de l\'ajout du type de dépense.')
        });

      } else {
        // Mise à jour d'un type existant
        this._typeDepenseService.updateTypeDepense(typeDepense).subscribe({
          next: () => this.showSuccessMessage('Type de dépense modifié avec succès.'),
          error: () => this.showErrorMessage('Une erreur s\'est produite lors de la modification du type de dépense.')
        });
      }
    }

    this.dialogRef.close(this.data);
  }

  showSuccessMessage(message: string) {
    this.dialog.open(SuccessDialogComponent, { data: { message } });
  }

  showErrorMessage(message: string) {
    this.dialog.open(SuccessDialogComponent, { data: { message } });
  }

  onCancel() {
    this.dialogRef.close();
  }
}
