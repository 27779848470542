<div class="list-container">
    <p class="app-titre-component">Gestion de paramétrage</p>
    <div style="padding: 20px;" class="mat-elevation-z8 appCommandeForm">
        <h2 class="app-titre-component">Liste des catégories</h2>
        <mat-table [dataSource]="dataSourceCategories" class="mat-elevation-z8 mat-table">
            <ng-container matColumnDef="libelle">
                <mat-header-cell *matHeaderCellDef> Libellé de la catégorie </mat-header-cell>
                <mat-cell *matCellDef="let categorie">{{ categorie.libelle }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="prixParGramme">
                <mat-header-cell *matHeaderCellDef> Prix par gramme </mat-header-cell>
                <mat-cell *matCellDef="let categorie"> {{ categorie?.prixParGramme }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
                <mat-cell *matCellDef="let categorie">
                    <button mat-icon-button (click)="editPrixOr(categorie)">
                        <mat-icon>edit</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumnsCategories"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumnsCategories;"></mat-row>
        </mat-table>
    </div>
    <div></div>
    <div style="padding: 20px; margin-top: 10px;" class="mat-elevation-z8 appCommandeForm">
        <div class="container">
            <h2 class="app-titre-component">Liste des types de dépenses</h2>
            <button mat-raised-button class="uniform-size" (click)="addTypeDepense()">
                <mat-icon>add</mat-icon> Ajouter un Type de Dépense
            </button>
        </div>
        <mat-table [dataSource]="dataSourceTypesDepenses" class="mat-elevation-z8 mat-table">
            <ng-container matColumnDef="libelle">
                <mat-header-cell *matHeaderCellDef> Type de dépense </mat-header-cell>
                <mat-cell *matCellDef="let typeDepense">{{ typeDepense.libelle }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
                <mat-cell *matCellDef="let typeDepense">
                    <button mat-icon-button (click)="editTypeDepense(typeDepense)">
                        <mat-icon>edit</mat-icon>
                    </button>

                    <button mat-icon-button color="warn" (click)="deleteTypeDepense(typeDepense)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumnsTypesDepenses"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumnsTypesDepenses;"></mat-row>
        </mat-table>
    </div>
</div>