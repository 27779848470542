import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Depense } from '../z-model/depense';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DepenseService {

  apiUrl = environment.baseUrl + 'api/depences';

  constructor(private _http: HttpClient) { }

  addDepense(depense: Depense): Observable<Depense> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this._http.post<Depense>(this.apiUrl, JSON.stringify(depense), { headers: headers });
  }
}
