import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TypeDepense } from '../z-model/type-depense';

@Injectable({
  providedIn: 'root'
})
export class TypeDepenseService {

  apiUrl = environment.baseUrl + 'api/type-depence';

  constructor(private _http: HttpClient) { }

  getTypeDepense(): Observable<TypeDepense[]> {
    return this._http.get<TypeDepense[]>(this.apiUrl);
  }

  updateTypeDepense(typeDepense: TypeDepense): Observable<TypeDepense> {
    return this._http.post<TypeDepense>(this.apiUrl, typeDepense);
  }

  deleteTypeDepense(id: string): Observable<void> {
    return this._http.delete<void>(this.apiUrl + '/' + id);
  }
}
